<template>
  <div>
    <br />
    <div class="row">
      <div class="col">
        <label for="">Client</label>
        <select class="form-select" :disabled="true" v-model="check.customer_reference">
          <option value="" default></option>
          <option
            v-for="(customer, index) in customers"
            :key="index++"
            :value="customer.reference"
          >
            <div class="row justify-content-between">
              <span class="col-auto">
                {{ customer.fullName }}
              </span>
              <span class="col-auto">
                {{ customer.society }}
              </span>
            </div>
          </option>
        </select>
      </div>

      <div class="col-2">
        <label for="">Montant</label>
        <input type="text" :disabled="true" v-model="check.amount" class="form-control" />
      </div>

      <div class="col-3">
        <label for="">Etat</label>
        <select v-model="check.status"  class="form-select">
          <option value="pending">Chèque en attente</option>
          <option value="paid">Chèque payé</option>
          <option value="rejected">Chèque rejeté</option>
        </select>
      </div>

      <div class="col-3">
        <label for="">Numéro de chèque</label>
        <input type="text" v-model="check.checkNumber" :disabled="true" class="form-control" />
      </div>
    </div>

    <br />
    <div class="row">
      <div class="col-12">
        <label for="">Remarques</label>
        <textarea
          v-model="check.remark"
          class="form-control"
          cols="30"
          rows="3"
        ></textarea>
      </div>
    </div>
    <br />
    <div class="row mt-3 justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(check)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      // payment: {},
    };
  },
  computed: {
    ...mapGetters("customer", {
      customers: "getAll",
    }),
    ...mapGetters("sale", { sales: "getSales" }),
    ...mapGetters("check", {
      check: "getCheck",
    }),
  },
  methods: {
    async save(data) {
      await this.$store.dispatch("check/update", data);
    },
    async getCustomerSalesUnpaid(customer_reference) {
      this.$store.dispatch("sale/getCustomerSalesUnpaid", customer_reference);
    },
  },
  beforeMount() {
    this.$store.dispatch("customer/getAll");
    this.$store.dispatch("check/show", this.$route.params.reference);
  },
};
</script>
